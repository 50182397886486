/* eslint-disable  */
export default async (context) => {
  const workbox = await window.$workbox;
  console.debug(workbox);
  if (!workbox) {
    console.debug('Workbox couldn\'t be loaded.');
    console.log('Workbox couldn\'t be loaded.');
    return;
  }

  console.log('Workbox was loading.');

  workbox.addEventListener('installed', (event) => {
    console.log(event);
    if (!event.isUpdate) {
      console.debug('The PWA is on the latest version.');
      return;
    }

    console.log('There is an update for the PWA, reloading...');
    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName);
      });
    });
    window.location.reload();
  });
};