import Vue from 'vue';
import {mapGetters} from 'vuex';
import SuluSender from '~/api/sulu-sender';

Vue.mixin({
  computed: {
    ...mapGetters(['isMobile']),
    isDev() {
      return /\/\/testapi.razlet.ru/.test(process.env.asb3URL);
    },
    isSulu() {
      return process.env.type === 'sulu';
    },
    suluUrl() {
      return process.env.suluURL || this.$store.state.host;
    },
  },
});

Vue.prototype.$sulu = new SuluSender();