/* eslint-disable no-unused-vars */
/* eslint-disable no-throw-literal */
import Vue from 'vue';
import {sendError} from '~/utils/error';
import FilesSender from '~/api/files-sender';
import Asb3Sender from '~/api/asb3-sender';
import SuluSender from '~/api/sulu-sender';

import ScheduleItem from '~/entities/schedule-item';
import TabloItem from '~/entities/tablo-item';

export const strict = false;

const filesSender = new FilesSender();
const asb3Sender = new Asb3Sender();

export const state = () => ({
  ua: '',
  mobile: false,
  weather: null,
  tablo: null,
  menu: [],
  /* alertMessage: null, */
  alertMessage: {id:'123'}, 
  about: {},
  defaultSearch: '',
  host: null,
  sitesList: [],
});

export const getters = {
  isMobile(state) {
    return state.mobile;
  },
  menu(state) {
    return state.menu;
  },
  weather(state) {
    return state.weather;
  },
  tablo(state) {
    return state.tablo;
  },
  alertMessage(state) {
    return state.alertMessage;
  },
  about(state) {
    return state.about;
  },
  sitesList(state) {
    return state.sitesList;
  },
};

export const mutations = {
  setUa(state, value) {
    state.ua = value;
  },
  setMobile(state, value) {
    state.mobile = value;
  },
  setValue(state, data) {
    Vue.set(state, data.key, data.value);
  },
  clearValue(state, data) {
    Vue.set(state, data.key, null);
  },
  setSitesList(state, data) {
    state.sitesList = data;
  },
};

export const actions = {
  async nuxtServerInit ({ dispatch, commit }, {req}) {
    console.log('start sulu requests');
    commit('setValue', {key: 'host', value: `https://${req.headers.host}`});
    dispatch('getWeather');
    /* await dispatch('getAlert'); */
    await dispatch('getAbout');
    await dispatch('getMenu');
    await dispatch('getSitesList');
  },
  // eslint-disable-next-line no-empty-pattern
  sendFiles({}, formData) {
    return filesSender.post('/files/upload/ajax/multi', formData);
  },
  getWeather({state, commit}) {
    const suluSender = new SuluSender(false, state.host);
    suluSender.get(`/weather.json`)
      .then(response => {
        if (!response) sendError(response);
        if (response.data.main) commit('setValue', {
          key:'weather', 
          value: {
            temp: Math.round(response.data.main.temp), 
            wind: Math.round(response.data.wind ? response.data.wind.speed : null),
            description: response.data.weather ? response.data.weather[0].description : '',
          },
        });
        return Promise.resolve(response.data.main ? response.data.main.temp : null);
      })
      .catch(e => {
        sendError(e);
      });
  },
  async getTablo({state}, {day = 'now', direction = 'departure'}) {
    const response = await asb3Sender.get(`/api/web/schedule/kras/flight-table?date=${day}&city=${state.about.code}&direction=${direction}`, null)
      .then(res => {
        if (!res.data.timeTable) return Promise.resolve([]);
        return Promise.resolve(res.data.timeTable.map(item => new TabloItem({tablo:item, dictionary: res.data.dictionaries})));
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
  async getAlert({dispatch, commit}) {
    const response = await dispatch('getData', '/api/snippet-areas/alert')
      .then(res => {
        const value = {
          id: res.id,
          description: res.content?.description,
        };
        commit('setValue', {key:'alertMessage', value});
        return Promise.resolve(res);
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
  async getAbout({dispatch, commit}) {
    const response = await dispatch('getData', '/api/snippet-areas/about_airports')
      .then(res => {
        if (!res) sendError(res);
        const value = {
          title: res.content?.title,
          code: res.content?.code,
          phone: res.content?.phone,
          image: res.content?.image?.url,
        };
        commit('setValue', {key:'about', value});
        commit('setValue', {key:'defaultSearch', value:`${res.content?.code || 'HTG'}0000KJA1000es`});
        return Promise.resolve(res);
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async getSchedule({state}, request = {type: 'departure'}) {
    let query = `${request.type}=${state.about.code}`;
    if (request.company) query += `company=${request.company}`;
    const response = await asb3Sender.get(`/api/web/schedule?${query}`, null)
      .then(res => {
        return Promise.resolve(res.data.schedule.map(item => new ScheduleItem({schedule:item, dictionary:res.data.dictionaries})));
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
  /* async getNews({dispatch}, params) {
    const path = `news/${params.pathMatch}`;
    const response = await dispatch('getData', `/${path}`)
      .catch(e => {
        sendError(e);
      });
    return response;
  },
  async getNewsList({dispatch}, page = 1) {
    const response = await dispatch('getData', `/news.json?p=${page}`, false)
      .catch(e => {
        sendError(e);
      });
    return response;
  }, */
  async getMenu({dispatch, commit}) {
    const response = await dispatch('getData', `/api/navigations/main?depth=3`)
      .then(res => {
        if (!res) sendError(res);
        const menu = res._embedded
          ? res._embedded.items.map(item => {
            return {
              name: item.title,
              items: item.children.map(child => {
                return {
                  name: child.title,
                  link: child.url,
                };
              }),
            };
          })
          : [];
        commit('setValue', {key:'menu', value: menu});
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
  async getSitesList({state, commit}) {
    const suluSender = new SuluSender(false, state.host);
    const response = await suluSender.get('/city/list.json')
      .then(res => {
        if (!res) sendError(res);
        const list = Array.isArray(res.data) ? res.data : [];
        commit('setSitesList', list);
        return Promise.resolve(list);
      })
      .catch(e => {
        commit('setSitesList', []);
        sendError(e);
      });
    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async getData({commit, state}, path, json = true) {
    if (typeof window !== 'undefined' && window.SULU_DATA) {
      return window.SULU_DATA;
    }
    const suluSender = new SuluSender(false, state.host);
    const response = await suluSender.get(`${path}${json ? '.json' : ''}`)
      .then((res) => {
        if (res) {
          if (res.status === 200 && res.data.redirect) {
            throw {
              status: 301,
              url: res.data.url.replace('.json', ''),
            };
          }
          if (res.status !== 200) {
            throw {
              status: res.status,
              message: res.statusText,
            };
          }
          return res.data;
        }
        return {};
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
};
