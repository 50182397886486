import Sender from './sender';

export default function sender() {
  const baseUrl = process.env.asb3URL || (process.client ? `${window.location.origin}/asb` : '/asb');
  const sender = new Sender({baseUrl});
  sender.request.interceptors.request.use((async (request) => {
    return {
      ...request,
      baseURL: baseUrl,
    };
  }));

  return sender;
};