export default class TabloItem {
  #item = null;

  #dictionary = null;

  statuses =  {
    by_schedule: 'По расписанию',
    complete: 'Рейс завершен',
    delay: 'Отложен',
    cancel: 'Отменен',
    registration: 'Началась регистрация',
  }
  
  errorStatuses = ['cancel', 'delay'];

  constructor(data) {
    this.#item = data.tablo;
    this.#dictionary = data.dictionary;
  }

  get company() {
    return this.#item.company;
  }

  get companyName() {
    return this.#dictionary.companies[this.company].name;
  }

  get number() {
    return this.#item.number;
  }

  get city() {
    return this.#item.city;
  }

  get cityName() {
    return this.#dictionary.cities[this.city].name;
  }

  get airport() {
    return this.#item.airport;
  }

  get airportName() {
    return this.#dictionary.airports[this.airport].name;
  }

  get plannedTime() {
    return this.#item.planedDate;
  }

  get realTime() {
    return this.#item.realFlightTime;
  }

  get status() {
    return this.#item.status;
  }

  get statusName() {
    return this.statuses[this.status];
  }

  get isError() {
    return this.errorStatuses.includes(this.status);
  }

  get isTimeChanged() {
    return this.realTime !== this.plannedTime;
  }
}