import Sender from './sender';

export default function sender() {

  const sender = new Sender({baseUrl: 'https://files.razlet.ru'});
  sender.request.interceptors.request.use((async (request) => {
    return {
      ...request,
      headers: {
        ...request.headers,
        'Content-Type': 'multipart/form-data',
      },
    };
  }));

  return sender;
}