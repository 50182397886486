import Vue from 'vue';

export default ({ app }) => {
  if (!Vue.prototype.$local) {
    Object.defineProperty(Vue.prototype, '$local', {
      get() {
        return app.i18n.locale;
      },
      set(locale) {
        app.i18n.locale = locale;
      },
    });
  }
};