import Vue from 'vue';
import Sender from './sender';

function sender(form = false, base = null) {
  const sender = new Sender({baseUrl: `${process.env.suluURL || base}/api/${Vue.prototype.$local}`});
  sender.request.interceptors.request.use((async (request) => {
    if (form) {
      return {
        ...request,
        headers: {
          ...request.headers,
          'Content-Type': 'multipart/form-data',
        },
      };
    }
    return request;
  }));
  return sender;
}

export default sender;
